import React, { lazy, Suspense } from 'react'
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider
} from 'react-router-dom'
import { BackdropComponent } from 'components'

import { GlobalLayoutComponent } from 'layout/Layout'
import { useMediaQuery, useTheme } from '@mui/material'
import { UnsuportedDevice } from 'pages/auth/unsuportedDevice/UnsuportedDevice'

const Landing = lazy(() => import('pages/landing/Landing'))
const Home = lazy(() => import('pages/auth/home/Home'))
const SignUp = lazy(() => import('pages/auth/signUp/SignUp'))
const SignIn = lazy(() => import('pages/auth/signIn/SignIn'))
const Logout = lazy(() => import('pages/auth/logout/Logout'))
const RedirectToGame = lazy(
  () => import('pages/auth/redirectToGame/RedirectToGame')
)
const Municipality = lazy(() => import('pages/municipality/Municipality'))

export const Router: React.FC = () => {
  const isMobileScreen = useMediaQuery(useTheme().breakpoints.down('md'))

  return (
    <Suspense fallback={<BackdropComponent open />}>
      <RouterProvider
        router={createBrowserRouter(
          createRoutesFromElements(
            <>
              <Route path='/' Component={GlobalLayoutComponent}>
                <Route
                  index
                  Component={!isMobileScreen ? Landing : UnsuportedDevice}
                />
                <Route path=':municipality' element={<Municipality />} />
                <Route path='auth'>
                  <Route index Component={Home} />
                  <Route path='logout' Component={Logout} />
                  <Route path='signin' Component={SignIn} />
                  <Route path='signup' Component={SignUp} />
                  <Route path='redirect-to-game' Component={RedirectToGame} />
                </Route>
              </Route>
              <Route path='*' element={<Navigate to='/' />} />
            </>
          )
        )}
      />
    </Suspense>
  )
}
